import useClientMutation from '../../common/useClientMutation';
import { ALERTS_REGISTERED_URL } from '../../consts';

import type {
  TagAlertsRegistrationInput,
  TagAlertsRegistrationOutput,
} from '@haaretz/s-data-structure-types';

type TagAlertsRegistrationInit =
  | { onSuccess?: (data: TagAlertsRegistrationOutput) => void }
  | undefined;

const resourceType = 'articleTag';

export default function useTagAlertsRegistration(props?: TagAlertsRegistrationInit) {
  const searchParams = new URLSearchParams({
    resourceType,
  });

  return useClientMutation<TagAlertsRegistrationOutput, TagAlertsRegistrationInput>({
    cacheKey: 'tagAlertsRegistration',
    url: ALERTS_REGISTERED_URL,
    searchParams,
    fetchOptions: { method: 'GET' },
    clientOptions: {
      useErrorBoundary: false,
      cacheTime: 0,
      retry: false,
      onSuccess: props?.onSuccess,
    },
  });
}

'use client';

import typesetter from '@haaretz/l-type.macro';
import { Tabs, Tab, TabList } from '@haaretz/s-tabs';
import React from 'react';
import s9 from 'style9';

import { SelectedPeriodContextUpdater, SelectedPeriodProvider } from './SelectedPeriodProvider';

import type { PeriodType } from '@haaretz/s-fragments/Types';

const c = s9.create({
  tab: {
    ...typesetter(-1),
  },
});

const periods: [PeriodType, string][] = [
  ['day', 'יומי'],
  ['week', 'שבועי'],
  ['month', 'חודשי'],
  ['year1', 'שנתי'],
  ['year3', '3 שנים'],
  ['year5', '5 שנים'],
] as const;

function LineGraphTabs({ children }: { children: React.ReactNode }) {
  const setSelectedPeriod = React.use(SelectedPeriodContextUpdater);

  return (
    <Tabs defaultSelectedTab={1}>
      <TabList>
        {periods.map(([key, value]) => (
          <Tab key={key} onClick={() => setSelectedPeriod(key)} styleExtend={[c.tab]}>
            {value}
          </Tab>
        ))}
      </TabList>
      {children}
    </Tabs>
  );
}

export default function TabsWrapper({ children }: { children: React.ReactNode }) {
  return (
    <SelectedPeriodProvider>
      <LineGraphTabs>{children}</LineGraphTabs>
    </SelectedPeriodProvider>
  );
}

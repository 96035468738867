import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';

import useClientQuery from '../../common/useClientQuery';
import { USER_IS_SUBSCRIBED } from '../../consts';

import type {
  UserIsSubscribedInput,
  UserIsSubscribedOutput,
} from '@haaretz/s-data-structure-types';

export default function useUserIsSubscribed(input: UserIsSubscribedInput) {
  const searchParams = new URLSearchParams(discardNullableValues(input));

  return useClientQuery<UserIsSubscribedOutput, UserIsSubscribedInput>({
    cacheKey: input.resourceType === 'articleTag' ? 'articleTagKey' : 'writerAlerts',
    url: USER_IS_SUBSCRIBED,
    searchParams,
    clientOptions: {
      useErrorBoundary: false,
      retry: false,
      enabled: !!input.email,
    },
  });
}

'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { StyleExtend } from '@haaretz/s-types';
import useBi from '@haaretz/s-use-bi';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { playbackRate } from '../utils/audioPlayerUtils';

const c = s9.create({
  base: {
    gridColumnStart: '1',
    gridColumnEnd: '2',
    gridRowStart: '3',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-start',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'center',
          gridColumnStart: '2',
          gridColumnEnd: '3',
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  button: {
    backgroundColor: 'inherit',
    fontWeight: 700,
    userSelect: 'none',
    minWidth: space(11),
    textAlign: 'start',
    ...typesetter(-1),
    ...merge(
      mq({
        from: 's',
        value: {
          marginInlineEnd: space(-4),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
});

interface ChangeSpeedButtonProps {
  styleExtend?: StyleExtend;
  buttonStyleExtend?: StyleExtend;
}

export default function ChangeSpeedButton({
  styleExtend = [],
  buttonStyleExtend = [],
}: ChangeSpeedButtonProps) {
  const { playbackIndex, onNextPlayBackRate, channelName } = useAudioPlayer();
  const biAction = useBi();
  const newPlaybackIndex = playbackIndex + 1 < playbackRate.length ? playbackIndex + 1 : 0;

  function onSpeedChangeClick() {
    onNextPlayBackRate();
    biAction({
      action_id: 161,
      page_type: 'Article',
      feature: 'podcast player',
      feature_type: 'Content',
      campaign_details: `speed - ${playbackRate[newPlaybackIndex]}x`,
      campaign_name: channelName ?? '',
    });
  }
  return (
    <div className={s9(c.base, ...styleExtend)}>
      <button
        onClick={onSpeedChangeClick}
        className={s9(c.button, ...buttonStyleExtend)}
        data-testid="changeSpeedButton"
      >
        {`${playbackRate[playbackIndex]}x`}
      </button>
    </div>
  );
}

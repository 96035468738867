'use client';
import Script from 'next/script';
import * as React from 'react';
import s9 from 'style9';

import type { FacebookFragment } from '@haaretz/s-fragments/embeds/Facebook.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  wrapper: {
    width: '100%',
    aspectRatio: '16/9',
    position: 'relative',
  },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
});

export interface FacebookProps extends FacebookFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

export default function Facebook({ source, embedType, showText, children }: FacebookProps) {
  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);
  const onLoad = () => {
    // @ts-expect-error - Facebook SDK adds FB property to window
    const FB = window.FB;
    if (FB) {
      FB.init({
        xfbml: true,
        version: 'v20.0',
      });
      FB.XFBML.parse();
    }
    setShowPlaceHolder(false);
  };

  if (!source || !embedType) return null;

  const fbClass =
    embedType === 'post' ? 'fb-post' : embedType === 'comments' ? 'fb-comment-embed' : 'fb-video';

  const style = `fb_iframe_widget ${s9(
    c.base,
    showPlaceHolder ? c.hidden : c.relative
  )} ${fbClass}`;

  const facebookSrc = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0';

  return (
    <div className={s9(c.wrapper)}>
      <Script src={facebookSrc} onLoad={onLoad} />
      {showPlaceHolder && children}
      {['post', 'comments'].includes(embedType) && (
        <div className={style} data-width="auto" data-href={source} />
      )}
      {embedType === 'video' && (
        <div
          className={style}
          data-width="auto"
          data-href={source}
          data-allowfullscreen="true"
          data-autoplay="false"
          data-show-text={showText}
        />
      )}
    </div>
  );
}

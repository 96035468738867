'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import HtzImage from '@haaretz/s-htz-image';
import * as React from 'react';
import s9 from 'style9';

import { useScrollytelling } from '../ScrollytellingProvider';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';

// `c` is short for `classNames`
const c = s9.create({
  img: {
    position: 'absolute',
    top: 0,
    left: '50%',
    opacity: 0,
    height: '100dvh',
    width: '100%',
    objectFit: 'cover',
    transform: 'translateX(-50%)',
    transition: 'opacity 0.5s',
    ...merge(
      mq({
        misc: 'landscape',
        value: {
          width: 'auto',
          marginInlineStart: 'auto',
          marginInlineEnd: 'auto',
        },
      })
    ),
  },
  current: {
    opacity: 1,
  },
});

interface ScrollyTellingImageProps extends ImageFragment {
  index: number;
}

const widths: number[] = [430, 645, 800, 1256, 1376];
const sizes: React.ComponentProps<typeof HtzImage>['sizes'] = [
  { misc: 'landscape', size: '85dvh' },
  { size: 'max(85dvh, 100vw)' },
] as const;

export default function ScrollyTellingImage({ index, ...image }: ScrollyTellingImageProps) {
  const imgCur = useScrollytelling();

  return (
    <HtzImage
      caption={image.caption}
      credit={image.credit}
      type={image.type}
      photographer={image.photographer}
      contentId={image.contentId || ''}
      url={image.url}
      alt={image.alt || ''}
      styleExtend={[c.img, imgCur === index && c.current]}
      imgData={image.files[0]}
      widths={widths}
      loading="lazy"
      sizes={sizes}
      aspect="vertical"
      data-testid="scrolli-img"
    />
  );
}

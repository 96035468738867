'use client';

import useArticleId from '@haaretz/s-atoms/articleId';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import browserStorageGetAndRemoveItem from '@haaretz/s-browser-storage-utils/getAndRemoveItem';
import getItem from '@haaretz/s-browser-storage-utils/getItem';
import removeItem from '@haaretz/s-browser-storage-utils/removeItem';
import useUpdateReadingHistory from '@haaretz/s-client-data-hooks/misc/useUpdateReadingHistory';
import useBi from '@haaretz/s-use-bi';
import React, { PropsWithChildren } from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

const c = s9.create({
  hidden: {
    display: 'none',
  },
});

interface ArticleBodyScrollObserverProps {
  articleBodyWrapperRef: React.RefObject<HTMLDivElement | null>;
}
export default function ArticleBodyScrollObserver({
  articleBodyWrapperRef,
  children,
}: PropsWithChildren<ArticleBodyScrollObserverProps>) {
  const articleId = useArticleId();
  const renderingKind = useRenderingKind();
  const biImpression = useBi('impression');
  const startTime = React.useMemo(() => Date.now(), []);
  const [previousListBiData, setPreviousListBiData] = React.useState<BiDataOverrides | null>(null);
  const gotBiArticleIdRef = React.useRef<string | null>(null);

  const { refetch: updateHistory } = useUpdateReadingHistory({
    variables: { readArticleId: articleId ?? undefined },
    inView: true,
    clientOptions: { enabled: false },
  });

  React.useEffect(() => {
    if (articleId && articleId !== gotBiArticleIdRef.current) {
      const storedArticleIdInfo = {
        key: 'articleId',
        storage: 'session',
      } as const;

      const storedArticleId = getItem(storedArticleIdInfo);

      if (storedArticleId && articleId && articleId === storedArticleId) {
        removeItem(storedArticleIdInfo);

        const abGroup =
          browserStorageGetAndRemoveItem({ key: 'ab_test_group', storage: 'session' }) ?? undefined;
        const abTestName =
          browserStorageGetAndRemoveItem({ key: 'ab_test_name', storage: 'session' }) ?? undefined;
        const abTestGroupLabel =
          browserStorageGetAndRemoveItem({ key: 'ab_test_group_label', storage: 'session' }) ??
          undefined;
        const recommendationType =
          browserStorageGetAndRemoveItem({ key: 'listOrigin', storage: 'session' }) ?? undefined;

        setPreviousListBiData({
          ab_test_group: abGroup,
          ab_test_group_label: abTestGroupLabel,
          ab_test_name: abTestName,
          additional_info: {
            ...(recommendationType && { origin: recommendationType }),
          },
        });
      } else {
        setPreviousListBiData({});
      }

      gotBiArticleIdRef.current = articleId;
    }
  }, [articleId]);

  const intersectionCount = React.useRef(0);

  React.useEffect(() => {
    if (
      !articleBodyWrapperRef.current?.children ||
      !previousListBiData ||
      renderingKind === 'blocked'
    ) {
      return () => {
        return undefined;
      };
    }

    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          entry.target.classList.add(s9(c.hidden));

          intersectionCount.current += 1;

          const currentTime = Date.now();

          biImpression({
            feature: 'Scroll',
            feature_type: 'Content',
            campaign_name: `${intersectionCount.current * 20}`,
            ...previousListBiData,
            additional_info: {
              ...previousListBiData?.additional_info,
              timeOnArticle: `${(currentTime - startTime) / 1000}`,
            },
          });

          if (intersectionCount.current === 2) {
            updateHistory();
          }

          observer[intersectionCount.current === 5 ? 'disconnect' : 'unobserve'](entry.target);
        }
      },
      { threshold: 1 }
    );

    Array.from(articleBodyWrapperRef.current.children)
      .slice(0, 5)
      .forEach(node => {
        observer.observe(node);
      });

    return () => {
      observer.disconnect();
    };
  }, [
    articleBodyWrapperRef,
    biImpression,
    previousListBiData,
    renderingKind,
    startTime,
    updateHistory,
  ]);

  return children;
}

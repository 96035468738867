'use client';
import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import typesetter from '@haaretz/l-type.macro';
import { useStockMarketGraphQuery } from '@haaretz/s-queries/StockMarketGraph';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import { extent } from 'd3-array';
import { axisLeft, axisTop } from 'd3-axis';
import { interpolatePath } from 'd3-interpolate-path';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { transition } from 'd3-transition';
import React from 'react';
import s9 from 'style9';

import InfoBlock from './BottomInfo';
import LineMovingIndicator from './LineMovingIndicator';
import { SelectedAssetProvider } from './SelectedLineAssetProvider';
import { SelectedPeriodContext } from './SelectedPeriodProvider';

import type { AreaGraphFragment } from '@haaretz/s-fragments/AreagGraph';
import type { LineGraphFragment } from '@haaretz/s-fragments/LineGraph';
import type { ScatterGraphFragment } from '@haaretz/s-fragments/ScatterGraph';
import type { GraphType, PeriodType } from '@haaretz/s-fragments/Types';

const c = s9.create({
  base: { width: '100%' },
  homepageBase: {
    borderRadius: radius('xLarge'),
    backgroundColor: color('neutral150'),
  },
  loading: {
    opacity: 0.7,
    backgroundColor: color('neutral300'),
    backgroundImage: `linear-gradient(95deg, transparent 0%, transparent 15%, ${color(
      'neutral600',
      { opacity: 0.4 }
    )} 50%, transparent 85%)` as string,
    backgroundSize: '300% 300%',
    animationName: s9.keyframes({
      '0%': { backgroundPositionX: '-50%' },
      '100%': { backgroundPositionX: '150%' },
    }),
    animationDuration: '3s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  tickLabel: {
    fontFamily: fontStack('primary'),
    color: color('neutral700'),
    ...typesetter(-3),
    ...merge(
      mq({
        from: 'xl',
        value: { ...typesetter(-1) },
      })
    ),
  },
  tickLabelDefault: {
    fontFamily: fontStack('primary'),
    color: color('neutral700'),
    ...typesetter(-2),
    ...merge(
      mq({
        from: 'xl',
        value: { ...typesetter(-1) },
      })
    ),
  },
  line: {
    width: '350px',
  },
});

const xTicksFormats: Record<PeriodType, { date: Intl.DateTimeFormatOptions; ticks: number }> = {
  day: { date: { hour: '2-digit', minute: '2-digit' }, ticks: 5 },
  week: { date: { weekday: 'short' }, ticks: 5 },
  month: { date: { day: '2-digit', month: '2-digit' }, ticks: 7 },
  year1: { date: { month: 'short' }, ticks: 5 },
  year3: { date: { month: 'short', year: '2-digit' }, ticks: 5 },
  year5: { date: { year: 'numeric' }, ticks: 5 },
  max: { date: { year: 'numeric' }, ticks: 5 },
} as const;

/*
 * CONSTS
 * We need them, because the graph is responsive and we need to adjust the graph's dimensions and margins according to the breakpoint.
 * Object keys are the names of the breakpoints
 */
/** Whole SVG dimensions */
export const DIMENSIONS = {
  default: { height: 320, width: 375 },
  s: { height: 280, width: 500 },
  m: { height: 300, width: 560 },
  l: { height: 280, width: 500 },
  xl: { height: 350, width: 600 },
  xxl: { height: 400, width: 710 },
} as const;

export const HOMEPAGE_DIMENSIONS = {
  default: { height: 320, width: 375 },
  s: { height: 210, width: 347 },
  m: { height: 225, width: 485 },
  l: { height: 280, width: 400 },
  xl: { height: 350, width: 560 },
  xxl: { height: 400, width: 720 },
} as const;

/** Margins that affect both x and y axes, and the graph itself */
export const MARGINS = {
  default: { bottom: 40, top: 12, left: 16, right: 24 },
  s: { bottom: 12, top: 12, left: 12, right: 16 },
  m: { bottom: 12, top: 20, left: 12, right: 16 },
  l: { bottom: 20, top: 20, left: 20, right: 24 },
  xl: { bottom: 48, top: 20, left: 20, right: 24 },
  xxl: { bottom: 48, top: 20, left: 20, right: 24 },
} as const;

/** Paddings that apply only to the graph itself */
export const PADDINGS = {
  default: { bottom: 0, top: 40, left: 64, right: 60 },
  s: { bottom: 0, top: 40, left: 60, right: 0 },
  m: { bottom: 0, top: 40, left: 60, right: 0 },
  l: { bottom: 0, top: 40, left: 60, right: 0 },
  xl: { bottom: 0, top: 40, left: 60, right: 0 },
  xxl: { bottom: 0, top: 40, left: 60, right: 0 },
} as const;

const observerOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '500px 0px 500px 0px',
};

type QueryProps = {
  assetId: string;
  period: PeriodType;
  type: GraphType;
  subType: string | undefined;
};

export default function LineGraph({
  assetId = '',
  homepage,
}: {
  assetId: string | null;
  homepage?: boolean;
}) {
  const selectedPeriod = React.use(SelectedPeriodContext);
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const dimensions = homepage ? HOMEPAGE_DIMENSIONS : DIMENSIONS;

  function queryParams(queryPeriod: PeriodType) {
    const res: QueryProps = {
      assetId: assetId ?? '',
      type: 'line',
      period: queryPeriod,
      subType: undefined,
    };
    return res;
  }

  const queryOpts = { enabled: typeof window !== 'undefined' && shouldFetch };

  const {
    data: rawData,
    isLoading,
    error,
  } = useStockMarketGraphQuery(
    { assetId: assetId ?? '', type: 'line', period: selectedPeriod, subType: undefined },
    { enabled: typeof window !== 'undefined' && shouldFetch }
  );

  /*
   * HACK: we want to populate React Query cache in advance
   * because we want to avoid glitches and jumps in the graphs
   * when the new data is fetched
   */
  useStockMarketGraphQuery(queryParams('day'), queryOpts);
  useStockMarketGraphQuery(queryParams('month'), queryOpts);
  useStockMarketGraphQuery(queryParams('year1'), queryOpts);
  useStockMarketGraphQuery(queryParams('year3'), queryOpts);
  useStockMarketGraphQuery(queryParams('year5'), queryOpts);

  if (error) console.error('error', error.message);

  const data = React.useMemo(
    () => rawData?.graph?.dataSource.filter(isLineGraphData) ?? [],
    [rawData?.graph?.dataSource]
  );

  const bp = useBreakpoint() ?? 'default';

  const observedRef = React.useRef<HTMLDivElement | null>(null);

  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const xRef = React.useRef<SVGGElement | null>(null);
  const yRef = React.useRef<SVGGElement | null>(null);
  const graphRef = React.useRef<SVGPathElement | null>(null);

  const xMinMax = React.useMemo(() => extent(data, d => d?.time ?? 1) as [number, number], [data]);

  const yMinMax = React.useMemo(
    () => (extent(data, d => d?.value ?? 1) as [number, number]) ?? [1, 1],
    [data]
  );

  // Y and X axes: set up the domain wi´th the min/max values, and apply margins to define its height and width
  const yScale = scaleLinear(
    [yMinMax[1], yMinMax[0]],
    [
      MARGINS[bp].top + PADDINGS[bp].top,
      dimensions[bp].height - MARGINS[bp].bottom - PADDINGS[bp].bottom,
    ]
  );
  const xScale = scaleLinear(xMinMax, [
    MARGINS[bp].left + PADDINGS[bp].left,
    dimensions[bp].width - MARGINS[bp].right,
  ]);

  const drawGraph = React.useCallback(() => {
    if (!svgRef.current || !xRef.current || !yRef.current || !graphRef.current) {
      return;
    }

    // This is flat line at the bottom
    const graphLine = line<LineGraphFragment>()
      .x(d => xScale(d.time ?? xMinMax[1]))
      .y(() => yScale(yMinMax[0]));

    // selectors to type less later
    const svgEl = select(svgRef.current);
    const xEl = select(xRef.current);
    const yEl = select(yRef.current);
    const graphEl = select(graphRef.current);

    svgEl
      .attr('perserveAspectRatio', 'xMinYMid')
      .attr('viewBox', [0, 0, dimensions[bp].width, dimensions[bp].height])
      .attr('style', 'max-width: 100%; height: auto;');

    // X AXIS
    xEl.call(
      axisTop(xScale)
        .ticks(xTicksFormats[selectedPeriod as PeriodType].ticks)
        // we typecast d as number, because it's initially assigned to d3 NumberValue type
        .tickFormat(d => formatTicks(d as number, selectedPeriod as PeriodType))
        .tickSizeOuter(0)
    );

    // X axis general settings
    xEl
      .select('.domain')
      // HACK: break out of the width constraints to allow the x axis domain to be full width
      .attr('d', `M0.5,0.5H${dimensions[bp].width}`)
      .attr('color', color('neutral300'))
      .attr('stroke', color('neutral300'));

    // X axis ticks
    xEl
      .attr('transform', `translate(0, ${MARGINS[bp].top + 16})`)
      .selectAll('.tick line')
      .attr('y1', '0')
      .attr('y2', '10')
      .attr('color', color('neutral300'))
      .attr('stroke', color('neutral300'));

    // X axis tick text
    xEl
      .selectAll('.tick text')
      .attr('y', -MARGINS[bp].top - 8)
      .attr('direction', 'rtl')
      .classed(s9(c.tickLabel), true)
      .attr('transform', `translate(0, ${MARGINS[bp].top})`);

    // Y AXIS
    yEl.call(axisLeft(yScale).ticks(5).tickSizeOuter(0));

    yEl.select('.domain').attr('color', color('neutral300')).attr('stroke', 'none');

    // force Y axis domain values be 100% width
    const tickLinePadding =
      bp === 'xl' || bp === 'xxl' ? MARGINS[bp].left + 60 : MARGINS[bp].left + 40;
    yEl
      .selectAll('.tick line')
      .attr('x1', tickLinePadding)
      .attr('x2', dimensions[bp].width)
      .attr('color', color('neutral300'))
      .attr('stroke', color('neutral300'));

    // Y axis tick text
    yEl
      .selectAll('.tick text')
      .attr('y', 0)
      .attr('x', MARGINS[bp].left)
      .attr('direction', 'rtl')
      .classed(s9(c.tickLabel), true);

    // Graph line
    graphEl
      .attr('fill', 'none')
      .attr('stroke', '#f69600')
      .attr('stroke-width', 1.5)
      .join(
        enter => enter.append('path').attr('d', graphLine(data)),
        update => {
          yEl.transition(transition().duration(800)).attr('opacity', 1);
          xEl.transition(transition().duration(800)).attr('opacity', 1);

          return (
            update
              .transition(transition().duration(1000))
              // attrTween makes smooth transitions between elements
              .attrTween('d', () => {
                const previous = (update.attr('d') ? update.attr('d') : graphLine(data)) ?? '';
                const current =
                  graphLine.x(d => xScale(d?.time)).y(d => yScale(d?.value))(data) ?? '';

                return interpolatePath(previous, current);
              })
          );
        },
        exit => exit.attr('d', graphLine(data)).remove()
      );
  }, [bp, data, dimensions, selectedPeriod, xMinMax, xScale, yMinMax, yScale]);

  // useEffect to fetch the data
  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setShouldFetch(true);
        observer.disconnect();
      }
    }, observerOptions);

    const curr = observedRef.current;
    if (curr) observer.observe(curr);

    return () => {
      if (curr) observer.unobserve(curr);
    };
  }, []);

  React.useEffect(() => {
    drawGraph();
  }, [drawGraph]);

  return (
    <SelectedAssetProvider>
      <div
        dir="ltr"
        className={s9(c.base, homepage && c.homepageBase, isLoading && c.loading)}
        ref={observedRef}
      >
        <React.Suspense fallback={null}>
          <svg ref={svgRef} height={dimensions[bp].height} width={dimensions[bp].width}>
            <g ref={xRef} />
            <g ref={yRef} />
            <path ref={graphRef} />
            <LineMovingIndicator data={data} xScale={xScale} yScale={yScale} homepage={homepage} />
          </svg>
          <InfoBlock period={selectedPeriod} homepage={homepage} />
        </React.Suspense>
      </div>
    </SelectedAssetProvider>
  );
}

export function formatTicks(date: number, period: PeriodType) {
  const parsedDate = new Date(date);

  const formattedDate =
    period === 'day'
      ? parsedDate.toLocaleTimeString('en-GB', xTicksFormats[period].date)
      : parsedDate.toLocaleDateString('en-GB', xTicksFormats[period].date);

  return formattedDate;
}

function isLineGraphData(
  candidate: LineGraphFragment | AreaGraphFragment | ScatterGraphFragment
): candidate is LineGraphFragment {
  return candidate.__typename === 'LineGraphData';
}

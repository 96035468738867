'use client';

import color from '@haaretz/l-color.macro';
import config from '@haaretz/l-config';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import ActionBtnPopover from '@haaretz/s-action-bar/ActionBtnPopover';
import useUser from '@haaretz/s-atoms/user';
import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import setItem from '@haaretz/s-browser-storage-utils/setItem';
import Chip from '@haaretz/s-chip';
import useClientQuery from '@haaretz/s-client-data-hooks/common/useClientQuery';
import ErrorBoundary from '@haaretz/s-error-boundary';
import Popover from '@haaretz/s-popover';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import useInterestChipActionMutation from './useInterestChipMutation';

const c = s9.create({
  base: {
    marginInlineStart: space(1),
    ...typesetter(-2),
    '--borderColor': color('primary1000', { opacity: 0.2 }),
    color: color('primary1000'),
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'none',
        },
      })
    ),
  },
});

const bidataForAnonymousUsers = {
  feature_type: 'Marketing',
  feature: 'follow on my htz popup',
  campaign_name: 'Haaretz Sheli',
  action_id: 3,
};
const bidataForOnboardingPopover = {
  feature_type: 'Onboarding',
  feature: 'follow on my htz popup',
  campaign_name: 'Haaretz Sheli',
};
type InterestAction = 'addInterest' | 'removeInterest' | 'checkInterest';
export interface InterestRegistrationChipProps {
  contentId: string;
  authorName: string;
}
interface ClientQueryResponse {
  exist: boolean;
  site: 'haaretz';
  status: string;
  version: string;
}

/* ARCHIVED success popover functionality
/ const INTEREST_INITIAL_COUNT = 0;
/ const ADD_INTEREST_COUNT = 'addInterestCount';
/
/ const bidataForSuccessPopover = {
/   feature_type: 'Content',
/   feature: 'follow on my htz success',
/   campaign_name: 'Haaretz Sheli',
/   action_id: 153,
/ };
*/
const SHOW_INTEREST_ONBOARDING_INITIAL_COUNT = 0;
const SHOW_INTEREST_ONBOARDING = 'showInterestOnMount';
const urlPrefix = config.get('brightspot');

const getShowOnboardingPopoverInitialCount = () => {
  const item = browserStorageGetItem({ key: SHOW_INTEREST_ONBOARDING, storage: 'local' });
  if (item) {
    return Number.parseInt(item) || 0;
  }
  return SHOW_INTEREST_ONBOARDING_INITIAL_COUNT;
};

function InterestChipFallback() {
  return (
    <Chip styleExtend={[c.base]} isPressed={false} kind="pressable">
      הארץ שלי
    </Chip>
  );
}

const buildInterestPath = (action: InterestAction, authorId: string) =>
  `${urlPrefix}/srv/siteUser?action=${action}&authorId=${authorId}`;

function InterestRegistrationChip({ contentId, authorName }: InterestRegistrationChipProps) {
  // success | error | nonPaying | onboarding - popover variant.
  // for now, we have only error, onboarding and  nonPaying
  const [popoverVariant, setPopoverVariant] = React.useState<
    'error' | 'nonPaying' | 'onboarding' | undefined
  >(undefined);
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);

  const chipRef = React.useRef<HTMLButtonElement>(null);
  const user = useUser('cookieValue');
  const biAction = useBi();
  const { data } = useClientQuery<ClientQueryResponse>({
    url: buildInterestPath('checkInterest', contentId),
    cacheKey: 'checkInterestChipRegistration',
    fetchOptions: { method: 'GET', credentials: 'include' },
    clientOptions: {
      enabled: user.isLoggedIn,
    },
  });
  const [isRegistered, setIsRegistered] = React.useState(!!data?.exist);

  React.useEffect(() => {
    const onboardingPopoverCount = getShowOnboardingPopoverInitialCount() + 1;
    // open popover on the 1st, 5th, 10th, 40th, 70th, 100th, etc. onmount
    if (!isRegistered) {
      if (onboardingPopoverCount % 1000 === 0) {
        setPopoverVariant('onboarding');
        setOpenPopover(true);
      }
    }
    setItem({ key: SHOW_INTEREST_ONBOARDING, value: onboardingPopoverCount, storage: 'local' });
  }, [popoverVariant, isRegistered]);

  const addInterestMutation = useInterestChipActionMutation({
    variables: { action: 'addInterest', authorId: contentId },
  });
  const removeInterestMutation = useInterestChipActionMutation({
    variables: { action: 'removeInterest', authorId: contentId },
  });
  const handleOpenPopoverStateChangeHandler = (boolean: boolean) => {
    setOpenPopover(boolean);
  };

  const biDataForRegistration = {
    campaign_name: 'follow writer',
    feature: 'follow on my htz - article page',
    feature_type: 'Content',
    newsletter_segment_id: contentId,
    newsletter_segment_name: authorName,
    newsletter_email: user.userMail,
  };

  async function handleClick() {
    if (user.userType === 'registered' || user.userType === 'anonymous') {
      setPopoverVariant('nonPaying');
      setOpenPopover(true);
      return;
    }
    if (!isRegistered) {
      const fetchRes = await addInterestMutation.mutateAsync();
      if (fetchRes.status === 'ok') {
        /* ARCHIVED - success popover functionality
        /
        / const addInterestCount = getAddInterestInitialCount() + 1;
        / setItem({ key: ADD_INTEREST_COUNT, value: addInterestCount, storage: 'local' });
        / // open popover on the 1st, 2nd, 3rd, 13th, 23rd, 33rd, etc. click
        / if (addInterestCount <= 3 || (addInterestCount > 3 && (addInterestCount - 3) % 10 === 0)) {
        /   setPopoverVariant('success');
        /   setOpenPopover(true);
        / }
        */
        setIsRegistered(true);
        biAction({ ...biDataForRegistration, action_id: 9, campaign_details: 'follow' });
      }
      if (fetchRes.status === 'error') {
        setPopoverVariant('error');
        setOpenPopover(true);
      }
      return;
    }
    if (isRegistered) {
      const fetchRes = await removeInterestMutation.mutateAsync();
      if (fetchRes.status === 'ok') {
        setIsRegistered(false);
        biAction({ ...biDataForRegistration, action_id: 8, campaign_details: 'unfollow' });
      }
    }
  }
  const onClose = () => {
    setPopoverVariant(undefined);
    setOpenPopover(false);
  };
  if (typeof window === 'undefined' || !user.userType) {
    return <InterestChipFallback />;
  }

  const ActionBtnPopoverVariants = {
    /* ARCHIVED success popover functionality - archived
    / success: (
    /   <ActionBtnPopover
    /     popoverType="success"
    /     onClose={onClose}
    /     title="הכותבים שבחרת מחכים לך"
    /     subTitle='פיד שמותאם במיוחד בשבילך נמצא בכפתור "הארץ שלי" בתפריט הניווט'
    /     biData={bidataForSuccessPopover}
    /     btn1={{
    /       text: 'קחו אותי להארץ שלי',
    /       onClick: () => {
    /         biAction(bidataForSuccessPopover);
    /       },
    /       buttonComponent: {
    /         href: 'https://www.haaretz.co.il/haaretz-sheli?utm_source=app&utm_medium=PopOver&utm_campaign=HaaretzSheli&utm_content=register',
    /         variant: 'brand',
    /         priority: 'tertiary',
    /       },
    /     }}
    /   />
    / ),
    */
    onboarding: (
      <ActionBtnPopover
        title="רק ללחוץ ולעקוב"
        onClose={onClose}
        biData={bidataForOnboardingPopover}
        subTitle={`קבלו את כל הכתבות של ${authorName} ב"הארץ שלי"`}
      />
    ),
    error: (
      <ActionBtnPopover
        popoverType="error"
        title="משהו השתבש"
        subTitle="אנא נסו שוב מאוחר יותר"
        onClose={onClose}
      />
    ),
    nonPaying: (
      <ActionBtnPopover
        popoverType="nonPaying"
        onClose={onClose}
        title="גם הארץ שלי יכול להיות שלך"
        biData={bidataForAnonymousUsers}
        subTitle="רק מנויים יכולים לעקוב אחרי הכותבים שהכי מעניינים אותם"
        btn1={{
          text: 'לרכישה',
          onClick: () => {
            biAction(bidataForAnonymousUsers);
          },
          buttonComponent: {
            href: 'https://openPurchasePage',
            variant: 'sales',
          },
        }}
      />
    ),
  };

  return (
    <>
      <Chip
        isPressed={isRegistered}
        onClick={handleClick}
        ref={chipRef}
        kind="pressable"
        styleExtend={[c.base]}
      >
        הארץ שלי
      </Chip>
      {popoverVariant && (
        <Popover
          refersToRef={chipRef}
          isOpen={openPopover}
          placement="top"
          onToggle={handleOpenPopoverStateChangeHandler}
          keepInView
        >
          {ActionBtnPopoverVariants[popoverVariant]}
        </Popover>
      )}
    </>
  );
}

export default function InterestRegistrationChipWrapper({
  contentId,
  authorName,
}: InterestRegistrationChipProps) {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<InterestChipFallback />}>
        <InterestRegistrationChip contentId={contentId} authorName={authorName} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

'use client';

import color from '@haaretz/l-color.macro';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import { bisector } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import React from 'react';
import s9 from 'style9';

import { HOMEPAGE_DIMENSIONS, DIMENSIONS, MARGINS } from './LineGraph';
import { SelectedAssetContextUpdater, SelectedAssetContext } from './SelectedLineAssetProvider';

import type { LineGraphFragment } from '@haaretz/s-fragments/LineGraph';

const c = s9.create({
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transitionDuration: '0.25s',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out',
  },
  displayNone: {
    display: 'none',
  },
});
export default function LineMovingIndicator({
  data,
  xScale,
  yScale,
  homepage,
}: {
  data: Array<LineGraphFragment>;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  homepage?: boolean;
}) {
  const bp = useBreakpoint() ?? 'default';
  const [isInTransition, setInTransition] = React.useState(false);

  const lineRef = React.useRef<SVGLineElement | null>(null);
  const circleRef = React.useRef<SVGCircleElement | null>(null);
  const overlayRef = React.useRef<SVGRectElement | null>(null);

  const setSelectedAsset = React.use(SelectedAssetContextUpdater);
  const selectedAsset = React.use(SelectedAssetContext) ?? data[data.length - 10];

  const memoSelectedAsset = React.useMemo(() => selectedAsset, [selectedAsset]);

  const dimensions = homepage ? HOMEPAGE_DIMENSIONS : DIMENSIONS;

  const getAssetFromPointerEvent = React.useCallback(
    (event: React.PointerEvent<SVGRectElement> | React.TouchEvent<SVGRectElement>) => {
      let xPos;
      if (event.type === 'touchmove') {
        event.preventDefault();
        xPos = (event as React.TouchEvent<SVGRectElement>).touches[0].clientX;
      } else {
        xPos = pointer(event)[0];
      }

      const xVal = xScale.invert(xPos);

      const index = bisector<LineGraphFragment, number>(d => d?.time).left(data, xVal);
      const assetIndex = index <= 0 ? 0 : data[index] ? index : index - 1;

      setSelectedAsset(data[assetIndex]);
    },
    [data, setSelectedAsset, xScale]
  );

  const moveLine = React.useCallback(() => {
    if (!data.length || !lineRef.current || !circleRef.current || !overlayRef.current) {
      return;
    }

    const lineEl = select(lineRef.current);
    // const polyEl = select(polyRef.current);
    const circleEl = select(circleRef.current);
    const overlayEl = select(overlayRef.current);

    const selectedAssetXPos = memoSelectedAsset
      ? xScale(memoSelectedAsset?.time ?? 0)
      : xScale(data[data.length - 1]?.time ?? 0);

    const selectedAssetYPos = yScale(memoSelectedAsset?.value ?? 0);

    lineEl
      .attr('x1', selectedAssetXPos)
      .attr('x2', selectedAssetXPos)
      .attr('y1', dimensions[bp].height)
      .attr('y2', 0)
      .attr('stroke', color('neutral600'))
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3 3');

    circleEl
      .attr('cx', selectedAssetXPos)
      .attr('cy', selectedAssetYPos)
      .attr('r', 5)
      .attr('fill', '#f69600');

    overlayEl
      .attr('pointer-events', 'all')
      .on('pointermove touchstart touchmove', getAssetFromPointerEvent);
  }, [bp, data, dimensions, getAssetFromPointerEvent, memoSelectedAsset, xScale, yScale]);

  React.useEffect(() => {
    moveLine();
  }, [moveLine]);

  React.useEffect(() => {
    setInTransition(true);

    const timeoutId = setTimeout(() => {
      const circleEl = select(circleRef.current);
      circleEl.attr('opacity', '0');
      setInTransition(false);
      setSelectedAsset(data[data.length - 1]);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [data, setSelectedAsset]);

  return (
    <>
      <rect
        ref={overlayRef}
        x={MARGINS[bp].left}
        y={MARGINS[bp].top}
        width={dimensions[bp].width}
        height={dimensions[bp].height}
        style={{ fill: 'none', pointerEvents: 'all' }}
      />
      <line ref={lineRef} className={s9(isInTransition ? c.hide : c.show)} />
      <circle ref={circleRef} className={s9(isInTransition ? c.hide : c.show)} />
    </>
  );
}

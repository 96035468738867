'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useRegistration from '@haaretz/s-auth-hooks/use-registration';
import Button from '@haaretz/s-button';
import ClickArea from '@haaretz/s-click-area';
import useTagAlertsRegistration from '@haaretz/s-client-data-hooks/alerts/useTagAlertsRegistration';
import useUserAlertsRegistration from '@haaretz/s-client-data-hooks/alerts/useUserAlertsRegistration';
import { siteId, site } from '@haaretz/s-consts';
import Icon from '@haaretz/s-icon';
import Popover from '@haaretz/s-popover';
import TextfieldWithButton from '@haaretz/s-text-field-with-button';
import useBi from '@haaretz/s-use-bi';
import emailValidator from '@haaretz/s-validators/emailValidator';
import * as React from 'react';
import s9 from 'style9';

import type { ButtonState } from '@haaretz/s-button';
import type { PopoverOwnProps } from '@haaretz/s-popover';
import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'flex',
    backgroundColor: color('neutral100'),
    flexDirection: 'column',
    paddingTop: space(2),
    paddingLeft: space(3),
    paddingRight: space(3),
    height: space(60),
    marginInlineEnd: space(3),
    width: '94vw',
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          width: space(95),
        },
      }),
      mq({
        from: 'xl',
        value: {
          height: space(63),
          width: space(120),
        },
      })
    ),
  },
  insideDiv: {
    paddingLeft: space(3),
    paddingRight: space(3),
    height: '100%',
  },
  closeIcon: {
    fontSize: space(4),
  },
  closeIconClickArea: {
    alignSelf: 'end',
    zIndex: zIndex('above'),
  },
  fontSize: {
    ...typesetter(1),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  disclaimer: {
    marginTop: space(3),
    ...typesetter(-2),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          paddingInlineEnd: space(20),
          ...typesetter(-3),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          paddingInlineEnd: space(20),
          ...typesetter(-4),
        },
      })
    ),
  },

  popoverTitle: {
    marginTop: space(1),
  },
  textField: {
    marginTop: space(5),
  },

  thankYouDiv: {
    gap: space(4),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    justifyContent: 'center',
  },
});

type ResourceType = 'writer' | 'tag' | 'blog';
export type ResourceTypeProps =
  | {
      /**
       * The resourceType is required for two things.
       * Firstly and most important for the type of graphql Mutation for the UserAlertsRegistration,
       * secondly, for the message to display
       */
      resourceType: Extract<ResourceType, 'writer' | 'blog'>;
      authorName: string;
      tagName?: never;
    }
  | {
      resourceType: Extract<ResourceType, 'tag'>;
      authorName?: never;
      tagName: string;
    };

export type AlertsSubscriptionPopoverProps = {
  /** Set Unsubscribed Button after successful registration */
  setUnsubscribeButton?: () => void;
  /** Set Registration State for parent. */
  setRegistrationSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * The BrightSpot contentId of the newsletter subscription
   */
  contentId: string;
  /** Popover placement
   * @defaultValue "top"
   */
  popoverPlacement: PopoverOwnProps['placement'];
  /**
   *  A callback that's fired whenever the open/closed state of the
   * popover is changed
   */
  onToggle: (willOpen: boolean) => void;
  /* control open/closed state from the outside **/
  isOpenFromParent: boolean;
  /** A ref to the element to which the popover is attached */
  refersToRef: React.MutableRefObject<Element | null>;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyAlertsSubscriptionPopover(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <AlertsSubscriptionPopover
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <AlertsSubscriptionPopover styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
} & ResourceTypeProps;

type EmailValidationState = 'default' | 'invalid';

const btnBusyNotice = fork({
  default: 'נרשם לניוזלטר',
  hdc: 'subscribing..',
});

const subscriptionText = {
  writer: fork({
    default: 'על כל כתבה של ',
    hdc: 'for new articles by ',
  }),
  tag: fork({
    default: 'כשיש כתבה חדשה על ',
    hdc: 'for new articles about ',
  }),
  blog: 'על כל כתבה חדשה של הבלוג של',
};

export default function AlertsSubscriptionPopover({
  setUnsubscribeButton,
  setRegistrationSuccess,
  onToggle,
  isOpenFromParent,
  contentId,
  resourceType,
  tagName,
  authorName,
  refersToRef,
  inlineStyle,
  popoverPlacement,
  styleExtend = [],
}: AlertsSubscriptionPopoverProps) {
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [btnState, setBtnState] = React.useState<ButtonState['state']>('auto');
  const [emailValidationState, setEmailValidation] =
    React.useState<EmailValidationState>('default');
  const [componentToShow, setComponentToShow] = React.useState<'subscription' | 'thankYou'>(
    'subscription'
  );
  const biAction = useBi();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const userAlertsMutation = useUserAlertsRegistration({
    onSuccess: data => {
      if (data.status === 'success' || data.status === 'pending') {
        setComponentToShow('thankYou');
      } else {
        console.error(`Alerts registration error: ${data.status}`);
      }
    },
    onError: data => {
      console.error(`Alerts registration error: ${data.message}`);
    },
  });
  const tagAlertsMutation = useTagAlertsRegistration({
    onSuccess: data => {
      if (data.status === 'success' || data.status === 'pending') {
        if (setRegistrationSuccess) setRegistrationSuccess(true);
        setComponentToShow('thankYou');
      }
    },
  });

  const registerToNewsletter = React.useCallback(async () => {
    const email = inputRef?.current?.value || '';
    const sharedBidata = {
      action_id: 9,
      campaign_details: 'follow',
      feature_type: 'Content',
      newsletter_segment_id: contentId,
      campaign_name: `follow ${resourceType}`,
      newsletter_email: email,
    };

    if (resourceType === 'tag') {
      await tagAlertsMutation.mutateAsync({
        email,
        site,
        isUnsubscribe: false,
        resourceId: contentId,
        caller: 'server',
      });
      biAction({
        feature: 'TagAlert - article page',
        newsletter_segment_name: tagName,
        ...sharedBidata,
      });
    } else {
      userAlertsMutation.mutate({
        email,
        isUnsubscribe: false,
        site,
        resourceType,
        resourceId: contentId,
        caller: 'server',
      });
      biAction({
        feature: `${resourceType === 'blog' ? 'Blog' : 'Writer'} Alert - article page`,
        newsletter_segment_name: authorName,
        ...sharedBidata,
      });
    }
  }, [
    resourceType,
    tagAlertsMutation,
    contentId,
    biAction,
    tagName,
    userAlertsMutation,
    authorName,
  ]);

  const registerToSite = useRegistration({
    onSuccess: data => {
      if (
        (data.registration.code === 403 &&
          data.registration.messages?.includes(
            'כתובת האימייל שהזנת נמצאת בשימוש. אנא נסו כתובת אחרת'
          )) ||
        (data.registration.code === 200 && data.registration.success)
      ) {
        registerToNewsletter();
      } else {
        setErrorMsg(
          fork({
            default: 'כתובת המייל אינה תקינה',
            hdc: 'Invalid email address',
          })
        );
        setEmailValidation('invalid');
      }
    },
    onError: data => {
      console.error(data.message);
    },
  });

  const onChange = React.useCallback(() => {
    if (errorMsg) setErrorMsg('');
  }, [errorMsg]);

  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setBtnState('busy');
    const email = inputRef?.current?.value || '';
    const isValid = emailValidator(email);
    if (!isValid.valid) {
      setErrorMsg(isValid.text);
      setEmailValidation('invalid');
    } else {
      await registerToSite.mutateAsync({
        input: {
          email,
          isEntitlement: false,
          trmsChk: 'on',
          restEntryType: 'NEWSLETTER_MINI_REG',
          siteId,
        },
      });
    }
  };

  const textFieldDescription = errorMsg
    ? errorMsg
    : fork({
        default: 'אנא הזינו כתובת מייל',
        hdc: 'Please enter your email',
      });

  const onClose = () => {
    onToggle(false);
  };

  const onCloseWhenSuccess = () => {
    onToggle(false);
    if (setUnsubscribeButton) setUnsubscribeButton();
  };

  return (
    <Popover
      isOpen={isOpenFromParent}
      refersToRef={refersToRef}
      inlineStyle={inlineStyle}
      keepInView={true}
      styleExtend={[c.base, ...styleExtend]}
      onToggle={onToggle}
      placement={popoverPlacement}
      closeOn="esc+blur"
    >
      <ClickArea size="extraSmall" styleExtend={[c.closeIconClickArea]}>
        <Icon icon="close" onClick={onClose} styleExtend={[c.closeIcon]} />
      </ClickArea>
      <div className={s9(c.insideDiv)}>
        {componentToShow === 'subscription' ? (
          <div>
            <h3 className={s9(c.popoverTitle, c.fontSize)}>שלחו לי התראה</h3>
            <p className={s9(c.fontSize)}>
              {subscriptionText[resourceType]}
              {resourceType === 'tag' ? tagName : authorName}
            </p>
            <form onSubmit={onSubmit}>
              <TextfieldWithButton
                btnContent={'שלחו'}
                btnProps={{
                  variant: 'brand',
                  type: 'submit',
                  ...(btnState === 'busy'
                    ? { state: btnState, busyNotice: btnBusyNotice }
                    : { state: btnState }),
                }}
                description={textFieldDescription}
                label={fork({ default: 'אימייל', hdc: 'Email' })}
                maxLength={64}
                onChange={onChange}
                isInvalid={emailValidationState !== 'default'}
                onInput={() => {
                  if (emailValidationState !== 'default') {
                    setEmailValidation('default');
                  }
                }}
                onInvalid={e => {
                  e.preventDefault();
                  setEmailValidation('invalid');
                }}
                pattern="\S+@\S+\.\S+"
                ref={inputRef}
                styleExtend={[c.textField]}
                type="email"
              />
              <p className={s9(c.disclaimer)}>
                בהרשמה אני מסכימ/ה לתנאי השימוש באתר, וכן לקבלת דיוורים והצעות למנוי מאתר הארץ /
                TheMarker.
              </p>
            </form>
          </div>
        ) : (
          <div className={s9(c.thankYouDiv)}>
            <h3>נרשמת בהצלחה</h3>
            <Button variant="brand" onClick={onCloseWhenSuccess}>
              סגור
            </Button>
          </div>
        )}
      </div>
    </Popover>
  );
}

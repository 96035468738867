'use client';

import React from 'react';

const STProvider = React.createContext<number | null | undefined>(undefined);

const STProviderUpdateContext = React.createContext<
  React.Dispatch<React.SetStateAction<number>> | undefined
>(undefined);

export default function ScrollytellingProvider({ children }: React.PropsWithChildren<unknown>) {
  const [imgCur, setImgCur] = React.useState<number>(0);

  return (
    <STProvider value={imgCur}>
      <STProviderUpdateContext value={setImgCur}>{children}</STProviderUpdateContext>
    </STProvider>
  );
}

export function useScrollytelling() {
  const context = React.use(STProvider);

  if (context === undefined) {
    throw new Error('useScrollytelling must be used within a STProvider');
  }

  return context;
}

export function useScrollytellingUpdate() {
  const context = React.use(STProviderUpdateContext);

  if (context === undefined) {
    throw new Error('useScrollytellingUpdate must be used within a STProviderUpdateContext');
  }

  return context;
}

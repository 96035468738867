import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';

import useClientQuery from '../../common/useClientQuery';
import { USER_REGISTRATION_ELEMENT } from '../../consts';

import type {
  UserRegistrationElementInput,
  UserRegistrationElementOutput,
} from '@haaretz/s-data-structure-types';

export default function useUserRegistrationElement(input: UserRegistrationElementInput) {
  const searchParams = new URLSearchParams(discardNullableValues(input));

  return useClientQuery<UserRegistrationElementOutput, UserRegistrationElementInput>({
    cacheKey: 'newsletterRegistration',
    url: USER_REGISTRATION_ELEMENT,
    searchParams,
    clientOptions: {
      useErrorBoundary: false,
      retry: false,
      enabled: !!input.sectionId,
    },
  });
}

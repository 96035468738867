import breakUrl from '../breakUrl';

const articleIdRegex =
  /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/i;

function getArticleIdFromURL(url: string) {
  if (!url) return null;

  const { pathname } = breakUrl(url);

  const result = articleIdRegex.test(pathname) ? pathname.match(articleIdRegex)?.[0] : null;

  return result || null;
}

export default getArticleIdFromURL;
